import logoArbuz from "../../src/assets/images/myImages/arbuz_full.svg";
import logoArbuzSm from "../../src/assets/images/myImages/arbuz.svg";
import logoTaqsim from "../../src/assets/images/myImages/logo.svg";
import logoTaqsimSm from "../../src/assets/images/myImages/logoSm.svg";
import logoProfi from "../../src/assets/images/myImages/profiLogo_full.svg"
import logoProfiSm from "../../src/assets/images/myImages/profiLogoSm.svg"
import logoTaqsimTitle from "../../src/assets/images/myImages/taqsimLogoTitle.svg"
import logoProfiTitle from "../../src/assets/images/myImages/profiLogoTitle.svg"
import logoArbuzTitle from "../../src/assets/images/myImages/arbuzLogoTitle.svg"

export const config = {
  BASE_URL: process.env.REACT_APP_BASE_URL,
  LOGOS: {
    orzu: logoProfi ,
    food: logoArbuz ,
    taqsim: logoTaqsim ,
  },
  LOGOS_SM: {
    orzu: logoProfiSm ,
    food: logoArbuzSm ,
    taqsim: logoTaqsimSm ,
  },
  LOGOS_TITLE: {
    orzu: logoProfiTitle,
    food: logoArbuzTitle,
    taqsim: logoTaqsimTitle
  },
  TITLES: {
    orzu: "Profi Market | Admin",
    food: "Arbuz market | Admin",
    taqsim: "Taqsim Market | Admin",
  },
  SITENAME: process.env.REACT_APP_PUBLIC_SITENAME,
   
  currency: function () {
    return process.env.REACT_APP_PUBLIC_SITENAME === "food" ? "aed" : "sum";
  },
};
