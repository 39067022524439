import React, { useEffect, useState } from "react";
import {
  Card,
  CardBody,
  Col,
  Container,
  Row,
  CardHeader,
  Form,
  Button,
  Spinner,
  input,
  Label,
  Input,
  Pagination,
  PaginationItem,
  PaginationLink,
} from "reactstrap";
import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import useHookForm from "../../../hooks/useHookForm";
import createSchema from "../../../helpers/createSchema";
import { useNavigate } from "react-router-dom";
import { useQueryClient, useQuery } from "@tanstack/react-query";
import { getProductsQuery, getTabProductQuery, getTabQuery } from "../../../queries";
import Loader from "../../../Components/Common/Loader";
import { addTabProduct } from "../../../api";
import Paginations from "../ui/pagination";

const values = { variationIds: [] };

const PodborkaProductAdd = () => {
  const [loading, setLoading] = useState(false);
  const [variationIds, setVariationIds] = useState([]);
  const [data, setData] = useState([]);
  const [inputId, setInputId] = useState([]);
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { id } = useParams();
  const QueryClient = useQueryClient();
  const [currentPage, setCurrentPage] = useState(1); // Default qiymat
  const [parent, setParent] = useState([])

  const schema = createSchema({
    variationIds: [],
  });
  const {
    formState: { errors },
    handleSubmit,
    watch,
    setValue,
    setError,
  } = useHookForm(values, schema);
  const { data: products, isFetched } = useQuery({
    ...getProductsQuery({
      page: currentPage,
      size: 100,
      moderationStatus: 1,
    }),
  });
  
  const {data: tab} = useQuery({
    ...getTabQuery(id)
  })

  const { data: tabsProduct} = useQuery({
    ...getTabProductQuery(id)
  })

  const handleCheckbox = (id) => {
    setParent((prevParent) =>
      prevParent.includes(id) ? prevParent : [...prevParent, id]
    );
    
    setVariationIds((prevSelectedIds) =>
      prevSelectedIds.includes(id)
        ? prevSelectedIds.filter((variationId) => variationId !== id)
        : [...prevSelectedIds, id]
    );
  };

  const onSubmit = handleSubmit(() => {
    setLoading(true);
    if (variationIds.length <= 0) {
      return setError("variationIds", {
        type: "manual",
        message: t("required"),
      });
    }

    addTabProduct(id, { variationIds })
      .then(() => {
        setLoading(false);
        navigate("/tabs");
        QueryClient.invalidateQueries(["tabs"]);
      })
      .catch((res) => {
        setLoading(false);
      });
  });
 
useEffect(() => {
  if (products && tabsProduct) {
    const selectedProducts = products
      ?.map((item) => ({
        id: item.variations?.[0]?.id,
        name: item.name,
        tabId: item.variations?.[0]?.tab,
      }))
      .flat();

    setData(selectedProducts);

    const preSelectedIds = tabsProduct
    .filter((tabItem) => {
  
      const isMatch = selectedProducts.some((product) => {
        return product?.id === tabItem.variations?.[0]?.id;
      });
  
  
      return isMatch;
    })
    .map((tabItem) => tabItem.variations?.[0]?.id);


    setVariationIds((prev) => [...new Set([...prev, ...preSelectedIds])]);
  }

  if (products?.pagination) {
    setCurrentPage(products.pagination.CurrentPage);
    setValue("page", products.pagination.CurrentPage);
  }
}, [products, tabsProduct, id]);


  document.title = "Categories Create | Taqsim";
  return (
    <div className="page-content">
      <Container fluid>
        {isFetched ? (
          <Row>
            <Col xl={12}>
              <Card>
                <CardHeader>
                  <div className="d-flex align-items-center gap-3">
                  <i
              className="ri-arrow-left-line fs-3 cursor-pointer"
              onClick={() => navigate(-1)}
            ></i>
                    <h5
                      className="card-title flex-grow-1 mb-0"
                      style={{ fontSize: "20px", fontWeight: 700 }}
                    >
                      {t("add_element")}
                    </h5>
                  </div>
                </CardHeader>
                <CardBody>
                  <Col xl={6}>
                    <Input
                      type="text"
                      className="form-control"
                      id="variationId1"
                      onChange={(e) => setInputId(e.target.value.split(","))}
                      value={inputId}
                    />
                    <Button
                      color="success"
                      className="btn btn-success w-100 mt-3"
                      type="submit"
                      onClick={() => {
                        inputId.forEach((item) => handleCheckbox(item));
                        setInputId("");
                      }}
                    >
                      {t("save_with_id")}
                    </Button>
                  </Col>
                </CardBody>
              </Card>
              <Card>
                <CardHeader>
                  {errors.variationIds && (
                    <span className="text-danger">
                      {errors.variationIds.message}
                    </span>
                  )}
                </CardHeader>
                <CardBody className="d-flex justify-content-between flex-wrap gap-4">
                  <Form action="#">
                    <Col xl={12} className="d-flex flex-wrap mt-4">
                      {data?.map((item) => (
                        <div
                          className="form-check mb-3"
                          key={item.id}
                          style={{ width: "50%" }}
                        >
                          <Input
                            className="form-check-input"
                            type="checkbox"
                            
                            id={`formCheck-${item.id}`}
                            checked={variationIds?.includes(item.id)}
                            onChange={() => handleCheckbox(item.id)}
                          />
                          <Label
                            className="form-check-label"
                            htmlFor={`formCheck-${item.id}`}
                          >
                            {item.name}
                          </Label>
                        </div>
                      ))}
                    </Col>
                    <Button
                      color="success"
                      className="btn btn-success w-100 mt-3"
                      type="submit"
                      onClick={onSubmit}
                    >
                      {loading ? (
                        <Spinner size="sm" className="me-2">
                          {"Loading..."}
                        </Spinner>
                      ) : null}
                      {t("save")}
                    </Button>
                  </Form>
                </CardBody>
              
        <Paginations  
         pagination={{
          currentPage: currentPage, // useState dan olingan currentPage
          totalPages: products?.pagination?.TotalPages || 1, // TotalPages
          onChange: (page) => {
            setCurrentPage(page); // Sahifani yangilash
            setValue("page", page); // Form state ni yangilash
          }}}
        />
              </Card>
            </Col>
          </Row>
        ) : (
          <Loader />
        )}
      </Container>
    </div>
  );
};

export default PodborkaProductAdd;
