// export function findParentId(data, categoryId) {
//   if (data) {
//     for (let i = 0; i < data.length; i++) {
//       if (data[i].childs && data[i].childs.length > 0) {
//         for (let j = 0; j < data[i].childs.length; j++) {
//           if (data[i].childs[j].id === categoryId) {
//             return {
//               parent: {
//                 value: data[i].id,
//                 label: data[i].name,
//                 array: data[i],
//               },
//               child: {
//                 value: data[i].childs[j].id,
//                 label: data[i].childs[j].name,
//               },
//             };
//           }
//           if (data[i].childs[j].childs && data[i].childs[j].childs.length > 0) {
//             for (let k = 0; k < data[i].childs[j].childs.length; k++) {
//               if (data[i].childs[j].childs[k].id === categoryId) {
//                 return {
//                   parent: {
//                     value: data[i].id,
//                     label: data[i].name,
//                     array: data[i],
//                   },
//                   child: {
//                     value: data[i].childs[j].id,
//                     label: data[i].childs[j].name,
//                     array: data[i].childs[j],
//                   },
//                   grandSon: {
//                     value: data[i].childs[j].childs[k].id,
//                     label: data[i].childs[j].childs[k].name,
//                     array: data[i].childs[j].childs[k],
//                   },
//                 };
//               }
//             }
//           }
//         }
//       } else if (data[i].childs === null && data[i].id === categoryId) {
//         return { value: data[i].id, label: data[i].name };
//       }
//     }
//   }
//   return null;
// }
export const findParentId = (data, categoryId) => {
  if (!data || !categoryId) return null;

  for (let i = 0; i < data.length; i++) {
    const parent = data[i];
    if (parent.id === categoryId) {
      return { parent: { value: parent.id, label: parent.name, array: parent } };
    }

    if (parent.childs?.length) {
      for (let j = 0; j < parent.childs.length; j++) {
        const child = parent.childs[j];
        if (child.id === categoryId) {
          return {
            parent: { value: parent.id, label: parent.name, array: parent },
            child: { value: child.id, label: child.name, array: child },
          };
        }

        if (child.childs?.length) {
          for (let k = 0; k < child.childs.length; k++) {
            const grandSon = child.childs[k];
            if (grandSon.id === categoryId) {
              return {
                parent: { value: parent.id, label: parent.name, array: parent },
                child: { value: child.id, label: child.name, array: child },
                grandSon: { value: grandSon.id, label: grandSon.name, array: grandSon },
              };
            }
          }
        }
      }
    }
  }
  return null;
};


export function dataTypeIndex(idx) {
  switch (idx) {
    case 0:
      return "Text";
    case 1:
      return "Integer";
    case 2:
      return "Float";
    case 3:
      return "Date";
    case 4:
      return "Bool";
    case 5:
      return "Char";

    default:
      break;
  }
}

export function serviceType(idx) {
  switch (idx) {
    case 0:
      return "Подборка";
    case 1:
      return "Сервис";
    case 2:
      return "Баннер";
    default:
      return <></>;
  }
}

export function serviceState(idx) {
  switch (idx) {
    case 0:
      return "Созданный";
    case 1:
      return "Одобренный";
    case 2:
      return 2;
    case 3:
      return "Отклоненный";
    case 9:
      return "Удаленный";
    default:
      return <></>;
  }
}

export function CheckStatus(status, t) {
  switch (status) {
    case 0:
      return (
        <span className="fs-7 badge text-uppercase badge-soft-warning">
          {t("orderStatus0")}
        </span>
      );
    case 1:
      return (
        <span className="fs-7 badge text-uppercase badge-soft-danger">
          {t("orderStatus1")}
        </span>
      );
    case 2:
      return (
        <span className="fs-7 badge text-uppercase badge-soft-secondary">
          {t("orderStatus2")}
        </span>
      );
    case 3:
      return (
        <span className="fs-7 badge text-uppercase badge-soft-info">
          {t("orderStatus3")}
        </span>
      );
    case 4:
      return (
        <span className="fs-7 badge text-uppercase badge-soft-primary">
          {t("orderStatus4")}
        </span>
      );
    case 5:
      return (
        <span className="fs-7 badge text-uppercase badge-soft-success">
          {t("orderStatus5")}
        </span>
      );
    default:
      return (
        <span className="fs-7 badge text-uppercase badge-soft-warning">
          {t("orderStatus6")}
        </span>
      );
  }
}
