import { Accordion, AccordionItem, Collapse, Button } from "reactstrap";
import classnames from "classnames";
import { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { deleteCategories } from "../../../api";
import { useQueryClient } from "@tanstack/react-query";
import ModalConfirmation from "../ui/modal";
import { useTranslation } from "react-i18next";

export const AccordionCategory = ({ cell, data }) => {
  const [isParentOpen, setIsParentOpen] = useState(false);
  const [childOpenStates, setChildOpenStates] = useState({});
  const [grandСhildOpenStates, setGrandChildOpenStates] = useState({});
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [deleteCategoryId, setDeleteCategoryId] = useState("");
  const { t } = useTranslation();

  function toggleModal(id) {
    setIsModalOpen(!isModalOpen);
    setDeleteCategoryId(id);
  }

  const toggleParent = () => {
    setIsParentOpen(!isParentOpen);
  };

  const toggleChild = (id) => {
    setChildOpenStates((prevState) => ({
      ...prevState,
      [id]: !prevState[id],
    }));
  };
  const toggleGrandChild = (id) => {
    setGrandChildOpenStates((prevState) => ({
      ...prevState,
      [id]: !prevState[id],
    }));
  };
const navigatePage = (id) => {
  window.open(`/categories/edit/${id}`)
}

const navigateAddPage = (id) => {
  window.open(`/categories/add/parent/${id}`)
}
  return (
    <AccordionItem>
      <ModalConfirmation
        title={t("delete_confirmations")}
        isOpen={isModalOpen}
        toggleConfirmation={toggleModal}
      >
        {
          <div className="d-flex justify-content-evenly">
            <Button
              color="success"
              className="w-25"
              onClick={() => toggleModal()}
            >
              {t("no")}
            </Button>
            <Button
              color="danger"
              className="w-25"
              onClick={() => {
                deleteCategories(deleteCategoryId).then((res) => {
                  queryClient.invalidateQueries(["categories"]);
                });
                toggleModal();
              }}
            >
              {t("yes")}
            </Button>
          </div>
        }
      </ModalConfirmation>
      <h2
        className="accordion-header d-flex justify-content-between align-items-center"
        id="headingOne"
      >
        <button
          className={classnames("accordion-button", {
            collapsed: !isParentOpen,
          })}
          type="button"
          onClick={toggleParent}
        >
          {cell.value}
        </button>
        <Link
        to={`/categories/add/parent/${data.id}`}
          className="ri-add-line fs-4 p-1"
        />
        <Link
        to={`/categories/edit/${data.id}`}
          className="ri-edit-line fs-4 p-1"
         
        />
        <btn
          className="ri-delete-bin-5-line fs-4 p-1"
          onClick={() => {
            toggleModal(cell.row.original.id);
          }}
          style={{ cursor: "pointer" }}
        />
      </h2>
      <Collapse
        isOpen={isParentOpen}
        className="accordion-collapse"
        id="collapseOne"
      >
        {data?.childs?.map((item) => (
          <div key={item.id}>
            <div className="accordion-body">
              <div
                className="accordion nesting2-accordion custom-accordionwithicon accordion-border-box"
                id="accordionnesting2"
              >
                <AccordionItem>
                  <h2
                    className="accordion-header d-flex justify-content-between align-items-center"
                    id="headingOne"
                  >
                    <button
                      className={classnames("accordion-button", {
                        collapsed: !childOpenStates[item.id],
                      })}
                      type="button"
                      onClick={() => toggleChild(item.id)}
                    >
                      {item.name}
                    </button>
                    <div className="accordion-body">
                      <Link
                        className="ri-add-line fs-4 p-1"
                        to={`/categories/add/parent/${item.id}`}                        
                      />
                      <Link
                        className="ri-edit-line fs-4 p-1"
                        to={`/categories/edit/${item.id}`}
                      />
                      <btn
                        className="ri-delete-bin-5-line fs-4 p-1"
                        onClick={() => {
                          toggleModal(item.id);
                        }}
                        style={{ cursor: "pointer" }}
                      />
                    </div>
                  </h2>
                  <Collapse
                    isOpen={childOpenStates[item.id]}
                    className="accordion-collapse"
                    id="collapseOne"
                  >
                    {item?.childs?.map((grandChild) => (
                      <div key={grandChild.id} className="accordion-body">
                        <h2
                          className="accordion-header d-flex justify-content-between align-items-center"
                          id="headingOne"
                        >
                          <button
                            className={classnames("accordion-button", {
                              collapsed: !grandСhildOpenStates[grandChild.id],
                            })}
                            type="button"
                            onClick={() => toggleGrandChild(grandChild.id)}
                          >
                            {grandChild.name}
                          </button>
                          <div className="accordion-body">
                            <Link
                              className="ri-edit-line fs-4 p-1"
                              to={`/categories/edit/${grandChild.id}`}
                            />
                            <btn
                              className="ri-delete-bin-5-line fs-4 p-1"
                              onClick={() => {
                                toggleModal(grandChild.id);
                              }}
                              style={{ cursor: "pointer" }}
                            />
                          </div>
                        </h2>
                        {/* <Collapse
                          isOpen={grandСhildOpenStates[grandChild.id]}
                          className="accordion-collapse"
                          id="collapseOne"
                        >
                          
                        </Collapse> */}
                      </div>
                    ))}
                  </Collapse>
                </AccordionItem>
              </div>
            </div>
          </div>
        ))}
      </Collapse>
    </AccordionItem>
  );
};
// deploy