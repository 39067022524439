import { useState, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useQuery } from "@tanstack/react-query";
import { Link } from "react-router-dom";
import {
  Card,
  CardBody,
  Col,
  Container,
  CardHeader,
  Row,
} from "reactstrap";
import BreadCrumb from "../../../Components/Common/BreadCrumb";
import TableContainer from "../../../Components/Common/TableContainer";
import Loader from "../../../Components/Common/Loader";

import { getCategoriesQuery, getOrganizationsQuery, getProductsQuery } from "../../../queries/index";
import "react-toastify/dist/ReactToastify.css";
import PageSize from "../ui/pageSize";
import FilterInputs from "../ui/filterInputs";

const values = {
  page: 1,
  size: 20,
  moderationStatus: 1,
};

const ConfirmProducts = () => {
  const { t } = useTranslation();
const [formValues, setFormValues] = useState(values);

  const { data, isFetched } = useQuery({
    ...getProductsQuery(formValues),
  });
 const { data: category } = useQuery({
    ...getCategoriesQuery({
      page: 1,
      size: 2147483647,
      isAll: true,
    }),
  });
  const { data: organization } = useQuery({
    ...getOrganizationsQuery({
      page: 1,
      size: 2147483647,
      isAll: true,
    }),
  });

  // Column
  const columns = useMemo(
    () => [
      {
        Header: t("product_name"),
        accessor: "name",
        filterable: false,
      },
      {
        Header: t("merchant"),
        accessor: "organizationName",
        filterable: false,
      },
      {
        Header: t("product_category"),
        accessor: "category.name",
        filterable: false,
      },
      {
        Header: t("isVisible"),
        accessor: "isVisible",
        filterable: false,
        Cell: (cell) => {
          return (
            <div
              className="text-center"
              style={{
                backgroundColor: cell.value === false ? "red" : "green",
                ...css.steteStyle,
              }}
            >
              {cell.value === false ? t("invisible") : t("visible")}
            </div>
          );
        },
      },
      {
        Header: t("state"),
        accessor: "state",
        filterable: false,
        Cell: (cell) => {
          return (
            <div
              className="text-center"
              style={{
                backgroundColor: cell.value === 0 ? "red" : "green",
                ...css.steteStyle,
              }}
            >
              {cell.value === 0 ? t("inactive") : t("active")}
            </div>
          );
        },
      },
      {
        Header: t("moderation_status"),
        accessor: "moderationStatus",
        filterable: false,
        Cell: (cell) => {
          return <div className="text-center">{t(`${cell.value}`)}</div>;
        },
      },
      {
        Header: "",
        accessor: "Action",
        Cell: (cell) => {
          return (
            <ul className="list-inline hstack gap-2 mb-0">
              <li className="list-inline-item">
                <Link
                  to={`/products/${cell.row.original.id}`}
                  className="text-primary d-inline-block"
                >
                  <i className="ri-eye-fill fs-16"></i>
                </Link>
              </li>
            </ul>
          );
        },
      },
    ],
    []
  );
  const handlePageChange = (page) => {
    setFormValues((prevState) => ({ ...prevState, page }));
  };
  document.title = "Products | Taqsim";
  const stateOption = [
    { value: 1, label: "Product_active" },
    {value: 0, label: "Product_deactive" },
  ];
  return (
    <div className="page-content">
      <Container fluid>
        <BreadCrumb title={t("products")} pageTitle={t("home")} />
        <Row>
          <Col lg={12}>
          <div className="d-flex justify-content-end w-100 mb-3">
          <FilterInputs
                    type="filter"
                    setFormValues={setFormValues}
                    fields={[
                      { name: "productName", placeholder: "product_name", width: "400px" },
                  
                   
                    
                    
                     
                    ]}
                  />
          </div>
            <Card id="orderList">
              <CardHeader className="d-flex justify-content-between align-items-start">
                <Row className="align-items-center gy-3">
                  <div className="col-sm">
                    <h5 className="card-title mb-0">{t("products")}</h5>
                  </div>
                </Row>
                <div className="d-flex justify-content-end gap-3 w-100">
                  <FilterInputs
                    type="filter"
                    setFormValues={setFormValues}
                    fields={[
                    
                      {
                        type: "select",
                        name: "state",
                        placeholder: "state",
                        label: "state",
                        options: stateOption.map((item) => ({
                          value: item.value,
                          label: t(item.label),
                        })),
                      },
                      
                      {
                        name: "organizationId",
                        type: "select",
                        label: "organization_name",
                        placeholder: "organization_name",
                        options: organization?.map((item) => ({
                          value: item.id,
                          label: item.organizationName,
                        })),
                      },
                   
                      { name: "categoryName", type: "category", placeholder: "category_name", options: category, valueName: "name" },
                    
                     
                    ]}
                  />
                
                </div>
              </CardHeader>
              <CardBody className="pt-0">
                <div>
                  <CardBody className="border border-dashed border-end-0 border-start-0"></CardBody>
                  {isFetched ? (
                    <>
                      <TableContainer
                       pagination={{
                        currentPage: formValues?.page,
                        totalPages: data?.pagination?.TotalPages,
                        onChange: handlePageChange,
                      }}
                        columns={columns}
                        data={data || []}
                        isGlobalFilter={false}
                        isAddUserList={false}
                        divClass="table-responsive table-card mb-1"
                        tableClass="align-middle table-nowrap"
                        theadClass="table-light text-muted"
                      />
                 <PageSize
                        formValues={formValues}
                        setFormValues={setFormValues}
                        tableKey={"confirm-products"}
                      />
                    </>
                  ) : (
                    <Loader />
                  )}
                </div>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default ConfirmProducts;

const css = {
  steteStyle: {
    color: "white",
    borderRadius: "10px",
    fontSize: "0.7rem",
    padding: "2px 4px",
  },
};
