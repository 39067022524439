import React, { useEffect, useState } from "react";
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  Form,
  Label,
  Spinner,
} from "reactstrap";
import useHookForm from "../../../hooks/useHookForm";
import createSchema from "../../../helpers/createSchema";
import { Input } from "../../../Components/atoms/input";
import Dropzone from "react-dropzone";
import RadioButtonInput from "../ui/radio-button";
import { createBrand, uploadPhoto } from "../../../api";
import { useNavigate, useParams } from "react-router-dom";
import { useQuery, useQueryClient } from "@tanstack/react-query";
import { getBrandById } from "../../../queries";
import { useTranslation } from "react-i18next";
import { toast, ToastContainer } from "react-toastify";
const values = {
  name: "",
  isVisible: null,
  imageId: "",
  iconId: "",
};

const schema = createSchema({
  name: "required",
  files: "",
  files2: "",
});
const BrandEdit = () => {
  const { id } = useParams();
  const { data } = useQuery({
    ...getBrandById(id),
  });
  const [filePreviews, setFilePreviews] = useState("");
  const [filePreviews2, setFilePreviews2] = useState("");
  const [isVisible, setIsVisible] = useState(false);
  const [loading, setLoading] = useState(false);
  const queryClient = useQueryClient();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const {
    setValue,
    control,
    formState: { errors },
    handleSubmit,
    watch,
    setError,
  } = useHookForm(values, schema);
  const handleFileUpload = async (acceptedFiles) => {
    const newFiles = acceptedFiles.map((file) => ({
      preview: URL.createObjectURL(file),
    }));
    setFilePreviews(...newFiles.map((f) => f.preview));
    setValue("files", acceptedFiles[0]);
  };
  const handleFileUpload2 = async (acceptedFiles) => {
    const newFiles = acceptedFiles.map((file) => ({
      preview: URL.createObjectURL(file),
    }));
    setFilePreviews2(...newFiles.map((f) => f.preview));
    setValue("files2", acceptedFiles[0]);
  };

  const Submit = async (res) => {
    if (!watch("files")) {
      return setError("files", {
        type: "manual",
        message: t("required"),
      });
    }
    if (!watch("files2")) {
      return setError("files2", {
        type: "manual",
        message: t("required"),
      });
    }
    setLoading(true);

    const datas = {
      id: parseInt(id),
      name: watch("name"),
      isVisible,
    };

    if (watch("files") !== data?.image?.url) {
      const formData = new FormData();
      formData.append("files", watch("files"));
      const image = await uploadPhoto(formData);
      datas.imageId = image?.[0]?.id;
    } else {
      datas.imageId = data?.image?.id;
    }

    if (watch("files2") !== data?.icon?.url) {
      const formData2 = new FormData();
      formData2.append("files", watch("files2"));
      const image2 = await uploadPhoto(formData2);
      datas.iconId = image2?.[0]?.id;
    } else {
      datas.iconId = data?.icon?.id;
    }

    try {
      await createBrand(datas, "PUT");
      queryClient.invalidateQueries({ queryKey: ["brand"] });
      queryClient.invalidateQueries({ queryKey: ["brandId"] });
      navigate("/brands");
    } catch (error) {
      toast.error(error)
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (data) {
      setValue("name", data?.name);
      const preview = data?.image?.url;
      setValue("files", preview);
      setFilePreviews(preview);
      const previewIcon = data?.icon?.url;
      setFilePreviews2(previewIcon);
      setValue("files2", previewIcon);
      setIsVisible(data?.isVisible);
    }
  }, [data]);
  
  return (
    <div className="page-content">
      <ToastContainer />
      <Card>
        <CardHeader>
          <div className="d-flex align-items-center gap-3">
            <i
              className="ri-arrow-left-line fs-3 cursor-pointer"
              onClick={() => navigate(-1)}
            ></i>
            <h5
              className="card-title flex-grow-1 mb-0"
              style={{ fontSize: "20px", fontWeight: 700 }}
            >
              {t("edit_brand")}
            </h5>
          </div>
        </CardHeader>
        <CardBody>
          <Form onSubmit={handleSubmit(Submit)}>
            <Input
              name="name"
              control={control}
              errors={errors}
              label="brand_name"
              inputProps={{
                placeholder: t("enter_name"),
              }}
            />

            <div className="d-flex align-items-center gap-5">
              <div className="d-flex gap-2 flex-wrap mt-3">
                <div>
                  <Label>{t("image")}</Label>
                  <div style={{ width: "300px" }}>
                    <Dropzone
                      onDrop={(acceptedFiles) =>
                        handleFileUpload(acceptedFiles)
                      }
                    >
                      {({ getRootProps, getInputProps }) => (
                        <div
                          {...getRootProps({ className: "dropzone" })}
                          style={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                            flexDirection: "column",
                            height: "80px",
                            border: "2px dashed #cccccc",
                            borderRadius: "5px",
                            cursor: "pointer",
                          }}
                        >
                          {!filePreviews && (
                            <div className="d-flex align-items-center justify-content-center">
                              <i className="bx bx-image-add display-4"></i>
                            </div>
                          )}
                          {filePreviews && (
                            <img
                              src={filePreviews}
                              alt={`Img`}
                              style={{
                                maxWidth: "100%",
                                maxHeight: "100%",
                                objectFit: "cover",
                                borderRadius: "5px",
                              }}
                            />
                          )}
                        </div>
                      )}
                    </Dropzone>
                  </div>
                  {errors.files && (
                    <span className="text-danger">{errors.files.message}</span>
                  )}
                </div>
              </div>
              <div className="d-flex gap-2 flex-wrap mt-3">
                <div style={{ width: "300px" }}>
                  <Label>{t("icon")}</Label>
                  <Dropzone
                    onDrop={(acceptedFiles) => handleFileUpload2(acceptedFiles)}
                  >
                    {({ getRootProps, getInputProps }) => (
                      <div
                        {...getRootProps({ className: "dropzone" })}
                        style={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                          flexDirection: "column",
                          height: "80px",
                          border: "2px dashed #cccccc",
                          borderRadius: "5px",
                          cursor: "pointer",
                        }}
                      >
                        {!filePreviews2 && (
                          <div className="d-flex align-items-center justify-content-center">
                            <i className="bx bx-image-add display-4"></i>
                          </div>
                        )}
                        {filePreviews2 && (
                          <img
                            src={filePreviews2}
                            alt={`Img`}
                            style={{
                              maxWidth: "100%",
                              maxHeight: "100%",
                              objectFit: "cover",
                              borderRadius: "5px",
                            }}
                          />
                        )}
                      </div>
                    )}
                  </Dropzone>
                  {errors.files2 && (
                    <span className="text-danger">{errors.files2.message}</span>
                  )}
                </div>
              </div>
            </div>
            <div className="mt-2">
              <RadioButtonInput
                label={"isVisible"}
                state={isVisible}
                setState={setIsVisible}
                id={1}
              />
            </div>

            <Button
              color="success"
              disabled={loading}
              type="submit"
              className="w-100 mt-2"
            >
              {loading ? (
                <div className="d-flex align-items-center justify-content-center gap-1">
                  <Spinner size="sm" /> {t("save")}
                </div>
              ) : (
                t("save")
              )}
            </Button>
          </Form>
        </CardBody>
      </Card>
    </div>
  );
};

export default BrandEdit;
