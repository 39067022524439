import { useState, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { Link } from "react-router-dom";
import {
  Card,
  CardBody,
  Col,
  Container,
  CardHeader,
  Row,
  Input,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
  Spinner,
} from "reactstrap";
import BreadCrumb from "../../../Components/Common/BreadCrumb";
import TableContainer from "../../../Components/Common/TableContainer";
import Loader from "../../../Components/Common/Loader";
import useHookForm from "../../../hooks/useHookForm";
import createSchema from "../../../helpers/createSchema";
import { getCategoriesQuery, getFiltersQuery } from "../../../queries/index";
import "react-toastify/dist/ReactToastify.css";
import { deleteFilters } from "../../../api";
import { toast } from "react-toastify";
import PageSize from "../ui/pageSize";
import FilterInputs from "../ui/filterInputs";

const schema = createSchema({});
const values = {
  page: 1,
  size: 20,
  sortBy: "id",
  desc: true,
};

const FiltersList = () => {
  const { t } = useTranslation();
  const QueryClient = useQueryClient();
  const [formValues, setFormValues] = useState(values)
  const [modal, setModal] = useState(false)
  const [loading, setLoading ] = useState(false)
  const [selectedId, setSelectedId] = useState(null);
  const toggleModal = (id) => {
    setSelectedId((prev) => (prev === id ? null : id));
    setModal((prev) => !prev);
  };
  const { data, isFetched } = useQuery({
    ...getFiltersQuery(formValues),
  });

  const {data: category} = useQuery({
    ...getCategoriesQuery({page: 1, size: 2147483647})
  })
  const { mutate } = useMutation({
    mutationFn: (id) => deleteFilters(id),
  });
  const handleDelete = (id) => {
    setLoading(true);
    mutate(id, {
      onSuccess: () => {
        toast.success(t("Successfully"));
        QueryClient.invalidateQueries({ queryKey: ["filters"] });
        setSelectedId(null);
      },
      onError: (error) => {
        alert(error.data?.error?.errorMessage);
      },
      onSettled: () => {
        setLoading(false);
      },
    });
  };
  
  // Column
  const columns = useMemo(
    () => [
      {
        Header: "ID",
        accessor: "id",
        filterable: false,
      },
      {
        Header: t("name"),
        accessor: "name",
        filterable: false,
      },
      {
        Header: t("category_name"),
        accessor: "category.name",
        filterable: false,
      },
      {
        Header: t("weight"),
        accessor: "weight",
        filterable: false,
      },
      {
        Header: t("filter_type"),
        accessor: "filterType",
        filterable: false,
      },
      {
        Header: t("data_type"),
        accessor: "dataType",
        filterable: false,
      },
    {
      accessor: "button",
      Cell: (cell) => {
        return (
          <>
            <btn
              className="ri-delete-bin-5-line fs-4 p-1"
              onClick={() => toggleModal(cell.row.original.id)}
              style={{ cursor: "pointer" }}
            />
            <Modal
              isOpen={selectedId === cell.row.original.id}
              toggle={() => toggleModal(cell.row.original.id)}
              modalClassName="zoomIn"
              centered
              size="sm"
            >
              <ModalHeader>{t("delete_confirmation")}</ModalHeader>
              <ModalBody>
                <i
                  className="ri-alert-fill text-warning d-flex justify-content-center"
                  style={{ fontSize: "50px" }}
                ></i>
                <p className="text-center">{t("text_delete")}</p>
              </ModalBody>
              <ModalFooter>
                <Button onClick={() => toggleModal(cell.row.original.id)}>
                  {t("cancel")}
                </Button>
                <Button
                  onClick={() => handleDelete(cell.row.original.id)}
                  className="btn btn-danger"
                >
                  {loading ? <Spinner size="sm" className="me-2" /> : null}{" "}
                  {t("delete")}
                </Button>
              </ModalFooter>
            </Modal>
          </>
        );
      },
    },
    

      {
        accessor: "Action",
        Cell: (cell) => {
          return (
            <ul className="list-inline hstack gap-2 mb-0">
              <li className="list-inline-item">
                <Link
                  to={`/filter/edit/${cell.row.original.id}`}
                  className="text-primary d-inline-block"
                >
                  <i className="ri-edit-line fs-4 p-1 fs-16"></i>
                </Link>
              </li>
            </ul>
          );
        },
      },
    ],
    [loading, selectedId, t]
  );
  const handlePageChange = (page) => {
    setFormValues((prevState) => ({ ...prevState, page }));
  };
  document.title = "Filters | Taqsim";

  return (
    <div className="page-content">
      <Container fluid>
        <BreadCrumb title={t("filters")} pageTitle={t("home")} />
        <Row>
          <Col lg={12}>
          <div className="d-flex justify-content-end gap-3 w-100 mb-3">
                  <FilterInputs
                    type="filter"
                    setFormValues={setFormValues}
                    fields={[     
                      { name: "categoryId", type: "category", placeholder: "category_name", options: category, valueName: "id" },                  
                    ]}
                  />
                
                </div>
            <Card id="orderList">
              <CardHeader className="card-header border-0">
                <Row className="align-items-center gy-3">
                  <div className="d-flex justify-content-between align-items-center col-sm">
                    <h5 className="card-title mb-0">{t("filters")}</h5>
                    <Link
                      to={"/filters/add"}
                      className="d-flex align-items-center"
                    >
                      <span>{t("add")}</span>
                      <btn
                        className="ri-add-line fs-4 p-1"
                        style={{ cursor: "pointer" }}
                      />
                    </Link>
                  </div>
                </Row>
              </CardHeader>
              <CardBody className="pt-0">
                <div>
                  <CardBody className="border border-dashed border-end-0 border-start-0"></CardBody>
                  {isFetched ? (
                    <>
                      <TableContainer
                          pagination={{
                            currentPage: formValues?.page,
                            totalPages: data?.pagination?.TotalPages,
                            onChange: handlePageChange,
                          }}
                        columns={columns}
                        data={data || []}
                        isGlobalFilter={false}
                        isAddUserList={false}
                        divClass="table-responsive table-card mb-1"
                        tableClass="align-middle table-nowrap"
                        theadClass="table-light text-muted"
                      />
                      <PageSize formValues={formValues} setFormValues={setFormValues} tableKey={"filters"} />
                    </>
                  ) : (
                    <Loader />
                  )}
                </div>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default FiltersList;
