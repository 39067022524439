import { useState } from "react";
import { useTranslation } from "react-i18next";
import {
  Card,
  CardBody,
  Col,
  Container,
  Row,
  Button,
  Form,
  Spinner,
} from "reactstrap";
import { Input } from "../../Components/atoms/input";
import { login } from "../../api/auth";
import ParticlesAuth from "../AuthenticationInner/ParticlesAuth";
import useHookForm from "../../hooks/useHookForm";
import createSchema from "../../helpers/createSchema";
import { useNavigate } from "react-router-dom";
import InputMasked from "../../Components/atoms/inputMask";

const schema = createSchema({
  login: "phone",
  password: "auth_password",
});
const values = {
  login: "998",
  password: "",
};

const Login = () => {
  const [loading, setLoading] = useState(false);
  const { t } = useTranslation();
  const navigate = useNavigate();

  const {
    control,
    formState: { errors },
    handleSubmit,
  } = useHookForm(values, schema);

  const onSubmit = handleSubmit((res) => {
    setLoading(true);
    const datas = {
      ...res,
      login: res.login.replace(/\s+/g, ""),
    };
    // console.log(datas)
    login(datas)
      .then((res) => {
        setLoading(false);
        localStorage.setItem("token", res.token);
        localStorage.setItem("access",JSON.stringify(res.access))
        navigate("/");
      })
      .catch((err) => {
        setLoading(false);
        alert(err.data?.error.message);
      })
  });

  document.title = "Авторизация | Taqsim Admin";

  return (
    <ParticlesAuth>
      <div className="auth-page-content">
        <Container>
          <Row className="justify-content-center mt-5">
            <Col md={8} lg={6} xl={5} className="mt-5">
              <Card className="mt-5">
                <CardBody className="p-4">
                  <div className="text-center mt-2">
                    <h5 className="text-primary">{t("welcome")}</h5>
                  </div>
                  <div className="p-2 mt-4">
                    <Form action="#">
                      <div className="mb-3">
                        <InputMasked
                          name="login"
                          control={control}
                          errors={errors}
                          label="phone"
                          inputProps={{
                            placeholder: t("phone"),
                          }}
                          maskProps={{ mask: "\\9\\9\\8 99 999 99 99" }}
                        />
                      </div>
                      <div className="mb-3">
                        {/* <div className="float-end">
                          <Link to="/forgot-password" className="text-muted">
                            Forgot password?
                          </Link>
                        </div> */}
                        <Input
                          name="password"
                          control={control}
                          errors={errors}
                          label="password"
                          inputProps={{
                            type: "password",
                            placeholder: t("password"),
                          }}
                        />
                      </div>

                      <div className="mt-4">
                        <Button
                          color="success"
                          className="btn btn-success w-100"
                          type="submit"
                          onClick={onSubmit}
                        >
                          {loading ? (
                            <Spinner size="sm" className="me-2">
                              {" "}
                              Loading...{" "}
                            </Spinner>
                          ) : null}
                          {t("sign_in")}
                        </Button>
                      </div>
                    </Form>
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </ParticlesAuth>
  );
};

export default Login;
