import axios from "axios";

const request = axios.create({
  baseURL: process.env.REACT_APP_BASE_URL,
  timeout: 60000,
});

export function errorHandler(error) {
  if (error.response) {
    if (error.response.status === 401) {
      localStorage.removeItem("token");
    }
    return Promise.reject(error.response);
  }
  if (error.request) {
    return Promise.reject(error.request);
  }
  return Promise.reject(error);
}
request.interceptors.request.use(
  (config) => {
    let lang = localStorage.getItem("i18nextLng");

    if (lang === "rs") {
      config.headers["Language"] = "ru";
    } else if (lang === "uz") {
      config.headers["Language"] = "uz-latn";
    } else {
      config.headers["Language"] = "uz-cyrl";
    }

    return config;
  },
  (error) => Promise.reject(error)
);
request.defaults.headers.timezone = new Date().getTimezoneOffset();

request.interceptors.request.use((config) => {
  const token = localStorage.getItem("token")
    ? localStorage.getItem("token")
    : "";
  if (token) {
    config.headers.Authorization = `Bearer ${token}`;
  }
  return config;
}, errorHandler);

request.interceptors.response.use((response) => {
  const pagination = response.headers?.["x-pagination"]
    ? JSON.parse(response.headers?.["x-pagination"])
    : "";

  const payload = response.data.result || response.data.item || response.data;
  if (pagination) {
    payload.pagination = pagination;
  }
  return payload;
}, errorHandler);

export default request;
