import { useState, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useQuery } from "@tanstack/react-query";
import { Link } from "react-router-dom";
import {
  Card,
  CardBody,
  Col,
  Container,
  CardHeader,
  Row,
  Button,
  Nav,
  NavItem,
  NavLink,
} from "reactstrap";
import BreadCrumb from "../../../Components/Common/BreadCrumb";
import TableContainer from "../../../Components/Common/TableContainer";
import Loader from "../../../Components/Common/Loader";

import {
  getCategoriesQuery,
  getOrganizationsQuery,
  getProductsQuery,
} from "../../../queries/index";
import "react-toastify/dist/ReactToastify.css";
import PageSize from "../ui/pageSize";
import FilterInputs from "../ui/filterInputs";
import classnames from "classnames";
import Select from "react-select"
const values = {
  page: 1,
  size: 20,
  moderationStatus: null
};

const Products = () => {
  const { t } = useTranslation();
  const [formValues, setFormValues] = useState(values);
  const { data, isFetched } = useQuery({
    ...getProductsQuery(formValues),
  });
  const { data: category } = useQuery({
    ...getCategoriesQuery({
      page: 1,
      size: 2147483647,
      isAll: true,
    }),
  });
  const { data: organization } = useQuery({
    ...getOrganizationsQuery({
      page: 1,
      size: 2147483647,
      isAll: true,
    }),
  });
  // Column
  const columns = useMemo(
    () => [
      {
        Header: t("product_name"),
        accessor: "name",
        filterable: false,
      },
      {
        Header: t("merchant"),
        accessor: "organizationName",
        filterable: false,
      },
      {
        Header: t("product_category"),
        accessor: "category.name",
        filterable: false,
      },
      {
        Header: t("isVisible"),
        accessor: "isVisible",
        filterable: false,
        Cell: (cell) => {
          return (
            <div
              className="text-center"
              style={{
                backgroundColor: cell.value === false ? "red" : "green",
                ...css.steteStyle,
              }}
            >
              {cell.value === false ? t("invisible") : t("visible")}
            </div>
          );
        },
      },
      {
        Header: t("state"),
        accessor: "state",
        filterable: false,
        Cell: (cell) => {
          return (
            <div
              className="text-center"
              style={{
                backgroundColor: cell.value === 0 ? "red" : "green",
                ...css.steteStyle,
              }}
            >
              {cell.value === 0 ? t("inactive") : t("active")}
            </div>
          );
        },
      },
      {
        Header: t("moderation_status"),
        accessor: "moderationStatus",
        filterable: false,
        Cell: (cell) => {
          const productStatus = cell.value;
          const statusKey =
            productStatus === "OnModeration"
              ? "On_moderate_products"
              : productStatus === "Confirmed"
              ? "Checked_products"
              : productStatus === "Rejected"
              ? "Reject_products"
              : "Unknown_status";
        
          return <div className="text-center">{t(statusKey)}</div>;
        },
        
      },
      {
        Header: "",
        accessor: "Action",
        Cell: (cell) => {
          return (
            <ul className="list-inline hstack gap-2 mb-0">
              <li className="list-inline-item">
                <Link
                  to={`/products/${cell.row.original.id}`}
                  className="text-primary d-inline-block"
                >
                  <i className="ri-eye-fill fs-16"></i>
                </Link>
              </li>
            </ul>
          );
        },
      },
    ],
    []
  );
  const handlePageChange = (page) => {
    setFormValues((prevState) => ({ ...prevState, page }));
  };
  document.title = "Products | Taqsim";
  const moderationStatusOption = [
    {value: null, label: "all"},
    { value: 0, label: "On_moderate_products" },
    { value: 1, label: "Checked_products" },
    { value: 3, label: "Reject_products" },
  ];
  const stateOption = [
    { value: 1, label: "Product_active" },
    {value: 0, label: "Product_deactive" },
  ];
  const handleNavClick = (value) => {
    setFormValues((prevState) => ({ ...prevState, moderationStatus: value }));
  };
  return (
    <div className="page-content">
      <Container fluid>
        <BreadCrumb title={t("products")} pageTitle={t("home")} />
        <Row>
          <Col lg={12}>
           <div className="d-flex justify-content-between align-items-center mb-2">
           <Nav
                    className="nav-tabs nav-tabs-custom nav-success"
                    role="tablist"
                  >
           
                     {moderationStatusOption.map((state) => (
                        <NavItem key={state}>
                          <NavLink
                            className={classnames(
                              { active: formValues?.moderationStatus === state.value },
                              "fw-semibold"
                            )}
                            onClick={() => handleNavClick(state.value)}
                            href="#"
                          >
                            {t(
                              state.label
                            )}
                          </NavLink>
                        </NavItem>
                      ))}
                  </Nav>
                  <FilterInputs
                    type="filter"
                    setFormValues={setFormValues}
                    fields={[
                      { name: "productName", placeholder: "product_name", width: "400px" },
                  
                   
                    
                    
                     
                    ]}
                  />
           </div>
            <Card id="orderList">
              <CardHeader className="">
                <Row className="align-items-center gy-3">
                  <div className="col-sm">
                    <h5 className="card-title mb-0">{t("products")}</h5>
                  </div>
                </Row>
                <div className="d-flex justify-content-end gap-3 w-100">
                  <FilterInputs
                    type="filter"
                    setFormValues={setFormValues}
                    fields={[
                    
                      {
                        type: "select",
                        name: "state",
                        placeholder: "state",
                        label: "state",
                        options: stateOption.map((item) => ({
                          value: item.value,
                          label: t(item.label),
                        })),
                      },
                      
                      {
                        name: "organizationId",
                        type: "select",
                        label: "organization_name",
                        placeholder: "organization_name",
                        options: organization?.map((item) => ({
                          value: item.id,
                          label: item.organizationName,
                        })),
                      },
                   
                      { name: "categoryName", type: "category", placeholder: "category_name", options: category, valueName: "name" },
                    
                     
                    ]}
                  />
                
                </div>
             
              </CardHeader>
              <CardBody className="pt-0">
                <div>
                  <CardBody className="border border-dashed border-end-0 border-start-0"></CardBody>
                  {isFetched ? (
                    <>
                      <TableContainer
                        pagination={{
                          currentPage: formValues?.page,
                          totalPages: data?.pagination?.TotalPages,
                          onChange: handlePageChange,
                        }}
                        columns={columns}
                        data={data || []}
                        isGlobalFilter={false}
                        isAddUserList={false}
                        divClass="table-responsive table-card mb-1"
                        tableClass="align-middle table-nowrap"
                        theadClass="table-light text-muted"
                      />
                      <PageSize
                        formValues={formValues}
                        setFormValues={setFormValues}
                        tableKey={"confirm-products"}
                      />
                    </>
                  ) : (
                    <Loader />
                  )}
                </div>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default Products;

const css = {
  steteStyle: {
    color: "white",
    borderRadius: "10px",
    fontSize: "0.7rem",
    padding: "2px 4px",
  },
};
