import { useState, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useQuery, useQueryClient } from "@tanstack/react-query";
import { Link } from "react-router-dom";
import * as moment from "moment";
import classnames from "classnames";
import {
  Card,
  CardBody,
  Col,
  Container,
  CardHeader,
  Nav,
  NavItem,
  NavLink,
  Row,
  Input,
  Button,
} from "reactstrap";
import BreadCrumb from "../../../Components/Common/BreadCrumb";
import TableContainer from "../../../Components/Common/TableContainer";
import Loader from "../../../Components/Common/Loader";
import useHookForm from "../../../hooks/useHookForm";
import createSchema from "../../../helpers/createSchema";
import { getReviewsQuery } from "../../../queries/index";
import "react-toastify/dist/ReactToastify.css";
import Rating from "react-rating";
import ModalConfirmation from "../ui/modal";
import { publishReviews, rejectReviews } from "../../../api";
import { toast, ToastContainer } from "react-toastify";

const schema = createSchema({});
const values = {
  page: 1,
  size: 20,
  type: 1,
  status: "",
};

const OrganizationReviews = () => {
  const { t } = useTranslation();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [modalState, setModalState] = useState("");
  const [reviewId, setReviewId] = useState("");
  const QueryClient = useQueryClient();

  function toggleModal(id) {
    setIsModalOpen(!isModalOpen);
    setReviewId(id);
  }

  const { watch, setValue } = useHookForm(values, schema);

  const { data: reviews, isFetched } = useQuery({
    ...getReviewsQuery({
      type: 1,
      ...watch(),
    }),
  });

  const PublishRejectButton = (status, id) => {
    if (status === 1) {
      publishReviews(id)
        .then(() => {
          QueryClient.invalidateQueries(["reviews"]);
          toast("Отзыв одобрен", {
            type: "success",
            theme: "colored",
            position: "top-center",
            autoClose: 2000,
          });
        })
        .catch((res) => toast.error(res));
    } else if (status === 2) {
      rejectReviews(id)
        .then(() => {
          QueryClient.invalidateQueries(["reviews"]);
          toast("Отзыв отклонен", {
            type: "success",
            theme: "colored",
            position: "top-center",
            autoClose: 2000,
          });
        })
        .catch((res) => toast.error(res));
    }
  };

  const columns = useMemo(
    () => [
      {
        Header: t("created_date"),
        accessor: t("date"),
        Cell: (review) => (
          <>{moment(review.row.original.date).format("DD.MM.YYYY")}</>
        ),
      },
      {
        Header: t("content_reviews"),
        accessor: "content",
        Cell: (review) => (
          <div style={{ width: "10rem" }}>
            <p style={{ wordBreak: "break-word", whiteSpace: "normal" }}>
              {review.row.original.content}
            </p>
            <Rating
              initialRating={review.row.original.rating}
              emptySymbol="mdi mdi-star-outline text-muted "
              fullSymbol="mdi mdi-star text-warning "
              className="fs-4"
              readonly
            />
          </div>
        ),
      },
      {
        Header: t("user_reviews"),
        accessor: "user",
        Cell: (review) => (
          <>
            <p>{review.row.original.user?.firstname}</p>
            <p>{review.row.original.user?.lastname}</p>
          </>
        ),
      },
      {
        Header: t("reviews_feedback"),
        accessor: "reply",
        Cell: (review) => (
          <div style={{ width: "15rem" }}>
            <p
              style={{
                wordBreak: "break-word",
                whiteSpace: "normal",
                fontWeight: 600,
                color: "#3577f1",
              }}
            >
              {review.row.original.reply.organization.name}
            </p>
            <p style={{ wordBreak: "break-word", whiteSpace: "normal" }}>
              {review.row.original.reply.content}
            </p>
          </div>
        ),
      },
      {
        Header: "",
        accessor: "button",
        Cell: (review) => {
          switch (watch("status")) {
            case 0:
              return (
                <div className="d-flex gap-3">
                  <Button
                    color="danger"
                    onClick={() =>
                      PublishRejectButton(2, review.row.original.id)
                    }
                    className="ri-spam-3-line"
                  />
                  <Button
                    color="success"
                    onClick={() =>
                      PublishRejectButton(1, review.row.original.id)
                    }
                    className="ri-checkbox-circle-line"
                  />
                </div>
              );

            case 1:
              return (
                <div className="d-flex gap-3">
                  <Button
                    color="danger"
                    onClick={() => {
                      setModalState("reject");
                      toggleModal(review.row.original.id);
                    }}
                    className="ri-spam-3-line"
                  />
                </div>
              );

            case 2:
              return (
                <div className="d-flex gap-3">
                  <Button
                    color="success"
                    onClick={() => {
                      setModalState("publish");
                      toggleModal(review.row.original.id);
                    }}
                    className="ri-checkbox-circle-line"
                  />
                </div>
              );
            default:
              break;
          }
        },
      },
    ],
    []
  );

  document.title = "Orders | Taqsim";

  return (
    <div className="page-content">
      <Container fluid>
        <ToastContainer />
        <BreadCrumb title={t("reviews_organizations")} pageTitle={t("home")} />
        <Row>
          <Col lg={12}>
            <Card id="orderList">
              <ModalConfirmation
                title={
                  modalState === "reject"
                    ? t("reject_confirmations")
                    : t("publish_confirmations")
                }
                isOpen={isModalOpen}
                toggleConfirmation={toggleModal}
              >
                {modalState === "reject" ? (
                  <Button
                    color="danger"
                    onClick={() => {
                      PublishRejectButton(2, reviewId);
                      toggleModal();
                    }}
                  >
                    {t("reject")}
                  </Button>
                ) : (
                  <Button
                    color="success"
                    onClick={() => {
                      PublishRejectButton(1, reviewId);
                      toggleModal();
                    }}
                  >
                    {t("publish")}
                  </Button>
                )}
              </ModalConfirmation>
              <Nav
                className="nav-tabs nav-tabs-custom nav-success"
                role="tablist"
              >
                <NavItem>
                  <NavLink
                    className={classnames(
                      { active: watch("status") === "" },
                      "fw-semibold"
                    )}
                    onClick={() => setValue("status", "")}
                    href="#"
                  >
                    <i className="ri-message-line me-1 align-bottom"></i>{" "}
                    {t("all_reviews")}
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink
                    className={classnames(
                      { active: watch("status") === 0 },
                      "fw-semibold"
                    )}
                    onClick={() => setValue("status", 0)}
                    href="#"
                  >
                    <i className="ri-chat-new-line me-1 align-bottom"></i>{" "}
                    {t("new_reviews")}
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink
                    className={classnames(
                      { active: watch("status") === 1 },
                      "fw-semibold"
                    )}
                    onClick={() => setValue("status", 1)}
                    href="#"
                  >
                    <i className="ri-chat-check-line me-1 align-bottom"></i>{" "}
                    {t("active_reviews")}
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink
                    className={classnames(
                      { active: watch("status") === 2 },
                      "fw-semibold"
                    )}
                    onClick={() => setValue("status", 2)}
                    href="#"
                  >
                    <i className="ri-chat-delete-line me-1 align-bottom"></i>{" "}
                    {t("unactive_reviews")}
                  </NavLink>
                </NavItem>
              </Nav>
              <CardBody className="pt-0">
                <div>
                  {isFetched ? (
                    <>
                      <TableContainer
                        pagination={{
                          currentPage: watch("page"),
                          totalPages: reviews?.pagination?.TotalPages,
                          onChange: (page) => setValue("page", page),
                        }}
                        columns={columns}
                        data={reviews?.items || []}
                        isGlobalFilter={false}
                        isAddUserList={false}
                        divClass="table-responsive table-card mb-1"
                        tableClass="align-middle table-nowrap"
                        theadClass="table-light text-muted"
                      />
                      <Input
                        type="select"
                        name="pageSize"
                        id="pageSize"
                        value={watch("size")}
                        onChange={(e) => {
                          const newSize = parseInt(e.target.value);
                          setValue("size", newSize);
                          setValue("page", 1);
                        }}
                        className="w-auto"
                      >
                        <option value={20}>{t("20")}</option>
                        <option value={50}>{t("50")}</option>
                        <option value={100}>{t("100")}</option>
                      </Input>
                    </>
                  ) : (
                    <Loader />
                  )}
                </div>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default OrganizationReviews;
