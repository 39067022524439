import React, { useEffect, useState } from "react";
import {
  Card,
  CardBody,
  Col,
  Container,
  Row,
  CardHeader,
  Input as ReactInput,
  Label,
} from "reactstrap";
import { useNavigate, useParams } from "react-router-dom";
import BreadCrumb from "../../../Components/Common/BreadCrumb";
import { useQuery } from "@tanstack/react-query";
import { getProductQuery } from "../../../queries/index";
import { useTranslation } from "react-i18next";
import { Input } from "../../../Components/atoms/input";
import useHookForm from "../../../hooks/useHookForm";
import { InputLabel } from "../../../Components/atoms/inputLabel";
import { Textarea } from "../../../Components/atoms/textarea";
import { config } from "../../../utils/config";

const values = {};
const schema = {};

const ProductView = () => {
  const { productId } = useParams();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const {
    control,
    formState: { errors },
    setValue,
  } = useHookForm(values, schema);

  const { data: product } = useQuery({
    ...getProductQuery(productId),
  });
  useEffect(() => {
    if (product) {
      setValue("categoryName", product.category.name);
      setValue("brandId", product?.brand?.id === 1 || product?.brand === null ? t("no_brand") : product?.brand?.name)
      product.names.map((item) =>
        setValue(`name${item.languageCode}`, item.text)
      );
      product.descriptions.map((item) =>
        setValue(`description${item.languageCode}`, item.text)
      );
      product.variations?.[0].prices.map((item) =>
        setValue(`price_${item.type}`, item.value)
      );
      product.variations?.[0].attributeValues.map((item) =>
        setValue(`value_${item.attribute?.name}`, item.value)
      );
    }
  }, [product]);
  let languages = ["ru", "uz-Cyrl-UZ", "uz-Latn-UZ"];
  if (config.SITENAME === "food") {
    languages.push("en");
    languages = languages.filter((lang) => lang !== "uz-Cyrl-UZ");
  }
  document.title = "Product Details | Taqsim";
  return (
    <div className="page-content">
      <Container fluid>
        <BreadCrumb title={t("products")} pageTitle={t("home")} />
        {product?.id && (
          <Row>
            <Col className="d-flex justify-content-center">
              <Card className="w-75">
                <CardHeader>
                  <div className="d-flex align-items-center gap-2">
                    <i
                      className="ri-arrow-left-line fs-3 cursor-pointer"
                      onClick={() => navigate(-1)}
                    ></i>
                    <h5
                      className="card-title flex-grow-1 mb-0"
                      style={{ fontSize: "20px", fontWeight: 700 }}
                    >
                      {t("products")}
                    </h5>
                  </div>
                </CardHeader>
                <CardBody className="d-flex justify-content-between">
                  <Row className="d-flex flex-column gap-3 w-50 ">
                    <Input
                      name="categoryName"
                      control={control}
                      errors={errors}
                      label="category_name"
                      inputProps={{
                        readOnly: true,
                      }}
                    />
                        <Input
                      name="brandId"
                      control={control}
                      errors={errors}
                      label="brand_name"
                      inputProps={{
                        readOnly: true,
                      }}
                    />
                    <div className="d-flex flex-column gap-3">
                      {languages.map((lang) => {
                        const item = product?.names?.find(
                          (name) => name.languageCode === lang
                        );
                        return (
                          <div key={lang}>
                            <Label className="fw-semibold">
                              {t(`name_${item.languageCode}`)}
                            </Label>
                            <ReactInput
                              value={item ? item.text : ""}
                              readOnly
                            />
                          </div>
                        );
                      })}
                    </div>

                    {languages.map((lang) => {
                      const item = product?.descriptions?.find(
                        (description) => description.languageCode === lang
                      );
                      return (
                        <div key={lang}>
                          <Label className="fw-semibold"></Label>
                          <Textarea
                            label={t(`name_${item.languageCode}`)}
                            inputProps={{
                              value: item ? item.text : "",
                              readOnly: true,
                            }}
                          />
                        </div>
                      );
                    })}
                    {product?.variations[0]?.prices?.map((item) => (
                      <Input
                        key={item.id}
                        name={`price_${item.type}`}
                        control={control}
                        errors={errors}
                        label={`${item.type === "Sale" ? "MSRP" : item.type}`}
                        inputProps={{
                          readOnly: true,
                        }}
                      />
                    ))}
                    <div
                      style={{
                        display: "grid",
                        textAlign: "center",
                        gridTemplateColumns: "repeat(3, 1fr)",
                        gap: "5px",
                        width: "100%",

                        marginTop: "10px",
                      }}
                    >
                      {product?.variations[0]?.files?.map((item) => (
                        <div id="img2" key={item.languageCode}>
                          <img
                            src={item?.url}
                            className="img-thumbnail rounded avatar-xxl"
                            alt={item?.id}
                          />
                        </div>
                      ))}
                    </div>
                  </Row>
                  <Row className="d-flex flex-column gap-3 w-50">
                    {product?.variations[0]?.attributeValues?.sort((a,b) => a.attribute.weight - b.attribute.weight)?.map(
                      (item) =>
                        item.attribute.isVisible &&
                        (!item.attribute.isValueTranslated ? (
                          <Input
                            key={item.id}
                            name={`value_${item.attribute?.name}`}
                            control={control}
                            errors={errors}
                            label={`${item.attribute?.name}`}
                            inputProps={{
                              readOnly: true,
                            }}
                          />
                        ) : (
                          item.attribute.isValueTranslated && (
                            <div>
                              <Label>{item.attribute?.name}</Label>

                              <div className="d-flex align-items-center gap-3">
                                {languages.map((lang) => {
                                  const translation =
                                    item?.valueTranslations?.find(
                                      (valueTranslation) =>
                                        valueTranslation.languageCode === lang
                                    );

                                  return (
                                    translation && (
                                      <div key={lang} className="w-100">
                                        <InputLabel
                                          label={
                                            lang === "uz-Latn-UZ"
                                              ? t("uz")
                                              : lang === "uz-Cyrl-UZ"
                                              ? t("cryl")
                                              : t(lang)
                                          }
                                          inputProps={{
                                            value: translation.text,
                                            readOnly: true,
                                          }}
                                        />
                                      </div>
                                    )
                                  );
                                })}
                              </div>
                            </div>
                          )
                        ))
                    )}
                  </Row>
                </CardBody>
              </Card>
            </Col>
          </Row>
        )}
      </Container>
    </div>
  );
};

export default ProductView;
