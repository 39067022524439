import React, { useEffect, useState } from "react";
import {
  Card,
  CardBody,
  Col,
  Container,
  Row,
  CardHeader,
  Form,
  Button,
  Spinner,
  Label,
  Input as InputFile,
} from "reactstrap";
import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import useHookForm from "../../../hooks/useHookForm";
import createSchema from "../../../helpers/createSchema";
import { createCategories, uploadPhoto, editCategories } from "../../../api";
import { Input } from "../../../Components/atoms/input";
import { useNavigate } from "react-router-dom";
import { useQueryClient, useQuery } from "@tanstack/react-query";
import { getCategoryQuery } from "../../../queries";
import Dropzone from "react-dropzone";
import RadioButtonInput from "../ui/radio-button";
import { config } from "../../../utils/config";

const values = {
  name: {
    ru: "",
    "uz-Cyrl-UZ": "",
    "uz-Latn-UZ": "",
    en: "",
  },
  imageId: "",
  iconId: "",
  isVisible: "",
};

const CategoriesCreate = () => {
  const [loading, setLoading] = useState(false);
  const [filePreviews, setFilePreviews] = useState("");
  const [iconPreviews, setIconPreviews] = useState("");
  const [isVisible, setIsVisible] = useState(false);
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { id, parentId } = useParams();
  const numbId = parseInt(id);
  const parent = parseInt(parentId);
  const QueryClient = useQueryClient();
  const isFood = config.SITENAME === "food";
  const schema = createSchema({
    nameru: "name",
    "nameuz-Latn-UZ": "name",
    ...(isFood ? { nameen: "name" } : { "nameuz-Cyrl-UZ": "name" }),
    files: "",
    iconFiles: "",
  });
  const {
    control,
    formState: { errors },
    handleSubmit,
    watch,
    setValue,
    setError,
  } = useHookForm(values, schema);

  const { data: category } = useQuery({
    ...getCategoryQuery(id),
    enabled: id !== undefined,
  });

  const handleFileUpload = async (acceptedFiles) => {
    const newFiles = acceptedFiles.map((file) => ({
      preview: URL.createObjectURL(file),
    }));
    setFilePreviews(...newFiles.map((f) => f.preview));
    setValue("files", acceptedFiles[0]);
  };

  const handleIconUpload = async (acceptedFiles) => {

    const newFiles = acceptedFiles.map((file) => ({
      preview: URL.createObjectURL(file),
    }));
    setIconPreviews(...newFiles.map((f) => f.preview));
    setValue("iconFiles", acceptedFiles[0]);
  };

  const onSubmit = handleSubmit(async (res) => {
    if (!watch("files")) {
      return setError("files", {
        type: "manual",
        message: t("required"),
      });
    }
    if (!watch("iconFiles")) {
      return setError("iconFiles", {
        type: "manual",
        message: t("required"),
      });
    }
    if (res) {
      const names = languages.map((languageCode) => ({
        languageCode,
        text: res[`name${languageCode}`],
      }));

      const datas = id
        ? {
            id: id,
            name: names,
            isVisible,
          }
        : parent
        ? {
            parentId: parent,
            name: names,
            isVisible,
          }
        : {
            name: names,
            isVisible,
          };

      setLoading(true);

      if (watch("files") !== category?.image?.url) {
        const formData = new FormData();
        formData.append("files", watch("files"));
        const image = await uploadPhoto(formData);
        datas.imageId = image?.[0]?.id;
      } else {
        datas.imageId = category?.image?.id;
      }

      if (watch("iconFiles") !== category?.icon?.url) {
        const formData2 = new FormData();
        formData2.append("files", watch("iconFiles"));
        const icon = await uploadPhoto(formData2);
        datas.iconId = icon?.[0]?.id;
      } else {
        datas.iconId = category?.icon?.id;
      }

      try {
        if (id) {
          await editCategories(datas);
          QueryClient.invalidateQueries(["categories"]);
          navigate("/categories");
        } else {
          await createCategories(datas);
          QueryClient.invalidateQueries(["categories"]);
          navigate("/categories");
        }
      } catch (error) {
        alert(error.data?.error);
      } finally {
        setLoading(false);
      }
    }
  });

  useEffect(() => {
    if (category?.name?.length >= 3) {
      category.name.map((item) =>
        setValue(`name${item.languageCode}`, item.text)
      );
      const previews = category?.image?.url;
      setFilePreviews(previews);

      const iconPreview = category?.icon?.url;
      setIconPreviews(iconPreview);
      setValue("files", category?.image?.url || null);
      setValue("iconFiles", category?.icon?.url || null);
      setIsVisible(category.isVisible);
    }
  }, [category]);
  let languages = ["ru", "uz-Cyrl-UZ", "uz-Latn-UZ"];
  if (config.SITENAME === "food") {
    languages.push("en");
    languages = languages.filter((lang) => lang !== "uz-Cyrl-UZ");
  }
  document.title = "Categories Create | Taqsim";
  return (
    <div className="page-content">
      <Container fluid>
        <Row>
          <Col xl={8}>
            <Card>
              <CardHeader>
                <div className="d-flex align-items-center gap-3">
                  <i
                    className="ri-arrow-left-line fs-3 cursor-pointer"
                    onClick={() => window.opener ? window.close() : navigate(-1)}
                  ></i>
                  <h5
                    className="card-title flex-grow-1 mb-0"
                    style={{ fontSize: "20px", fontWeight: 700 }}
                  >
                    {id ? t("edit_category") : t("add_category")}
                  </h5>
                </div>
              </CardHeader>
              <CardBody>
                <Form action="#" className="d-flex flex-column gap-3">
                  <div className="d-flex flex-column gap-3">
                    {languages.map((langCode) => (
                      <div key={langCode}>
                        <Input
                          label={t(`name_${langCode}`)}
                          control={control}
                          errors={errors}
                          name={`name${langCode}`}
                          inputProps={{
                            placeholder: t("enter_name"),
                          }}
                        />
                      </div>
                    ))}
                  </div>
                  <div className="d-flex align-items-center gap-5">
                    <div>
                      <Label>{t("image")}</Label>
                      <div className="d-flex gap-2 flex-wrap">
                        <div style={{ width: "300px", marginBottom: "20px" }}>
                          <Dropzone
                            accept="image/jpeg, image/png, image/jpg,"
                            onDrop={(acceptedFiles) =>
                              handleFileUpload(acceptedFiles)
                            }
                          >
                            {({ getRootProps, getInputProps }) => (
                              <div
                                {...getRootProps({ className: "dropzone" })}
                                style={{
                                  display: "flex",
                                  alignItems: "center",
                                  justifyContent: "center",
                                  flexDirection: "column",
                                  height: "80px",
                                  border: "2px dashed #cccccc",
                                  borderRadius: "5px",
                                  cursor: "pointer",
                                }}
                              >
                                {!filePreviews && (
                                  <div className="d-flex align-items-center justify-content-center">
                                    <i className="bx bx-image-add display-4"></i>
                                  </div>
                                )}
                                {filePreviews && (
                                  <img
                                    src={filePreviews}
                                    alt={`Img`}
                                    style={{
                                      maxWidth: "100%",
                                      maxHeight: "100%",
                                      objectFit: "cover",
                                      borderRadius: "5px",
                                    }}
                                  />
                                )}
                              </div>
                            )}
                          </Dropzone>
                        </div>
                      </div>
                      <span>{t("Recommended image size 250x250px")}</span>
                      {errors.files && (
                        <span className="text-danger">
                          {errors.files.message}
                        </span>
                      )}
                    </div>
                    <div style={{ marginBottom: "22px" }}>
                      <Label>{t("icon")} SVG</Label>
                      <div className="d-flex gap-2 flex-wrap">
                        <div style={{ width: "300px", marginBottom: "20px" }}>
                          <Dropzone
                            accept="image/svg+xml"
                            onDrop={(acceptedFiles) =>
                              handleIconUpload(acceptedFiles)
                            }
                          >
                            {({ getRootProps, getInputProps }) => (
                              <div
                                {...getRootProps({ className: "dropzone" })}
                                style={{
                                  display: "flex",
                                  alignItems: "center",
                                  justifyContent: "center",
                                  flexDirection: "column",
                                  height: "80px",
                                  border: "2px dashed #cccccc",
                                  borderRadius: "5px",
                                  cursor: "pointer",
                                }}
                              >
                                {!iconPreviews && (
                                  <div className="d-flex align-items-center justify-content-center">
                                    <i className="bx bx-image-add display-4"></i>
                                  </div>
                                )}
                                {iconPreviews && (
                                  <img
                                    src={iconPreviews}
                                    alt={`Img`}
                                    style={{
                                      maxWidth: "100%",
                                      maxHeight: "100%",
                                      objectFit: "cover",
                                      borderRadius: "5px",
                                    }}
                                  />
                                )}
                              </div>
                            )}
                          </Dropzone>
                        </div>
                      </div>
                      {errors.iconFiles && (
                        <span className="text-danger">
                          {errors.iconFiles.message}
                        </span>
                      )}
                    </div>
                  </div>
                  <RadioButtonInput
                    label={"isVisible"}
                    state={isVisible}
                    setState={setIsVisible}
                    id={1}
                  />

                  <Button
                    color="success"
                    className="btn btn-success w-100"
                    type="submit"
                    onClick={onSubmit}
                  >
                    {loading ? (
                      <Spinner size="sm" className="me-2">
                        {" "}
                        Loading...{" "}
                      </Spinner>
                    ) : null}
                    {t("save")}
                  </Button>
                </Form>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default CategoriesCreate;
