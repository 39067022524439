import React, { useEffect, useState } from "react";
import {
  Card,
  CardBody,
  Col,
  Container,
  Row,
  CardHeader,
  Label,
  Button,
  Input as TextInput,
} from "reactstrap";
import { useNavigate, useParams } from "react-router-dom";
import { useQuery, useQueryClient } from "@tanstack/react-query";
import { getTabQuery } from "../../../queries/index";
import { useTranslation } from "react-i18next";
import {
  serviceState,
  serviceType,
} from "../../../helpers/findParentCategoryId";
import { Input } from "../../../Components/atoms/input";
import useHookForm from "../../../hooks/useHookForm";
import { deleteTabItem } from "../../../api";
import { toast, ToastContainer } from "react-toastify";
import ModalConfirmation from "../ui/modal";
import { config } from "../../../utils/config";

const schema = {};
const values = {};

const ServceDetail = () => {
  document.title = "Service Details | Taqsim";
  const { id } = useParams();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const {
    control,
    formState: { errors },
    setValue,
  } = useHookForm(values, schema);
  const QueryClient = useQueryClient();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [deleteItem, setDeleteItem] = useState("");

  function toggleModal(id) {
    setIsModalOpen(!isModalOpen);
    setDeleteItem(id);
  }

  const { data: tab } = useQuery({
    ...getTabQuery(id),
  });

  useEffect(() => {
    if (tab) {
      tab.names.map((item) => setValue(`name${item.languageCode}`, item.text));
      setValue("state", serviceState(tab.state));
      setValue("type", serviceType(tab.type));
      setValue("order", tab.order);
    }
  }, [tab]);
  let languages = ["ru", "uz-Cyrl-UZ", "uz-Latn-UZ"];
  let bannerLang = ["ru-RU", "uz-Cyrl-UZ", "uz-Latn-UZ"];

  if (config.SITENAME === "food") {
    bannerLang.push("en");
    bannerLang = bannerLang.filter((lang) => lang !== "uz-Cyrl-UZ");
  }
  if (config.SITENAME === "food") {
    languages.push("en");
    languages = languages.filter((lang) => lang !== "uz-Cyrl-UZ");
  }
  return (
    <div className="page-content">
      <Container fluid>
        <ToastContainer />
        <Row>
          <Col xl={12}>
            <Card>
              <ModalConfirmation
                title={t("delete_confirmations")}
                isOpen={isModalOpen}
                toggleConfirmation={toggleModal}
              >
                {
                  <div className="d-flex justify-content-evenly">
                    <Button
                      color="success"
                      className="w-25"
                      onClick={() => toggleModal()}
                    >
                      {t("no")}
                    </Button>
                    <Button
                      color="danger"
                      className="w-25"
                      onClick={() => {
                        deleteTabItem(id, deleteItem)
                          .then(() => {
                            toast("Элемент удален", {
                              type: "error",
                              theme: "colored",
                              position: "top-center",
                              autoClose: "2000",
                            });
                            QueryClient.invalidateQueries(["tab"]);
                          })
                          .catch((error) => toast.error(error));
                        toggleModal();
                      }}
                    >
                      {t("yes")}
                    </Button>
                  </div>
                }
              </ModalConfirmation>
              <CardHeader className="d-flex justify-content-between align-items-center">
                <div className="d-flex align-items-center gap-3">
                  <i
                    className="ri-arrow-left-line fs-3 cursor-pointer"
                    onClick={() => navigate(`/banner`)}
                  ></i>
                  <h5
                    className="card-title flex-grow-1 mb-0"
                    style={{ fontSize: "20px", fontWeight: 700 }}
                  >
                    {"Информация о сервисе"}
                  </h5>
                </div>
                <div className="d-flex align-items-center">
                <i className="ri-add-line fs-4 p-1"></i>
                  <Button onClick={() => navigate(`/service/item/add/${id}`)} color="none" className="border-0 p-0">{t("add_element")}</Button>
                </div>
              </CardHeader>
              <CardBody className="d-flex justify-content-between flex-wrap gap-4">
                <Row className="d-flex flex-column gap-2 w-50 col-5">
                  <Input
                    control={control}
                    errors={errors}
                    name="type"
                    label="tab_type"
                    inputProps={{
                      disabled: true,
                    }}
                    isInteger
                    maxLength={1}
                  />
                  <Input
                    control={control}
                    errors={errors}
                    name="state"
                    label="tab_state"
                    inputProps={{
                      disabled: true,
                    }}
                    isInteger
                    maxLength={1}
                  />
                  <Input
                    control={control}
                    errors={errors}
                    name="order"
                    label="order"
                    inputProps={{
                      disabled: true,
                    }}
                    isInteger
                    maxLength={1}
                  />
                </Row>
                <Row className="d-flex flex-column gap-2 w-50 col-5">
                  <div className="d-flex flex-column gap-2">
                    {languages.map((langCode) => (
                      <div key={langCode}>
                        <Input
                          label={t(`name_${langCode}`)}
                          control={control}
                          errors={errors}
                          name={`name${langCode}`}
                          inputProps={{
                            readOnly: true,
                          }}
                        />
                      </div>
                    ))}
                  </div>
                </Row>
              </CardBody>
            </Card>

            {tab?.items.length > 0 && (
              <Card >
                <Label className="p-3">{t("elements")}</Label>
                <div className="d-flex justify-content-between w-100 gap-5 p-3">
                  {languages.map((item) => (
                    <span
                      key={item}
                      className="w-50 border p-2 text-center form-control "
                    >
                      {item === "en"
                        ? t("english")
                        : item === "ru"
                        ? t("ru-RU")
                        : item === "uz-Latn-UZ"
                        ? t("uz-Latn-UZ")
                        : item === "uz-Cyrl-UZ" && t("uz-Cyrl-UZ")}
                    </span>
                  ))}
                </div>
                <CardBody className="d-flex justify-content-between gap-5">
                  {bannerLang.map((language) => (
                    <Row
                      key={language}
                      className="d-flex flex-column gap-3 w-100"
                    >
                      <div
                        className="d-grid gap-4"
                        style={{
                          gridTemplateColumns: "repeat(1, 1fr)",
                          gridAutoRows: "minmax(100px, auto)",
                        }}
                      >
                        {tab?.items
                          ?.sort((a, b) => a.order - b.order)
                          .filter((item) => item.languageCode === language)
                          .map((item) => (
                            <div
                              className="border form-control d-flex flex-column gap-3"
                              id="img2"
                              key={item.id}
                              style={{ height: "420px",   boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.25)" }}
                            >
                              <div className="d-flex justify-content-between align-items-center gap-3">
                                <div>
                                  <Label style={{ marginBottom: "3px" }}>
                                    {t("order")}
                                  </Label>
                                  <TextInput value={item.order} disabled />
                                </div>
                                <div>
                                  <Label
                                    style={{ marginBottom: "3px" }}
                                    hidden={tab.type !== 2}
                                  >
                                    {t("language")}
                                  </Label>
                                  <TextInput
                                    value={t(item.languageCode)}
                                    disabled
                                    hidden={tab.type !== 2}
                                  />
                                </div>
                              </div>

                              <div>
                                <Label style={{ marginBottom: "3px" }}>
                                  {t("link")}
                                </Label>
                                <TextInput value={item.link} disabled />
                              </div>
                              <div className="w-100 mt-3">
                                <img
                                  src={item?.fileLink}
                                  alt={item.fileId}
                                  style={{
                                    width: "100%",
                                    height: "160px",
                                    borderRadius: "16px",
                                    objectFit: "contain",
                                  }}
                                />
                              </div>
                              <div className="d-flex align-items-end justify-content-between mt-3">
                                <Button
                                  color="danger"
                                  style={{ width: "150px" }}
                                  onClick={() => {
                                    toggleModal(item.id);
                                  }}
                                >
                                  <span>{t("delete_element")}</span>
                                </Button>
                                <Button
                                  color="success"
                                  style={{ width: "150px" }}
                                  onClick={() =>
                                    navigate(
                                      `/service/${id}/item/${item.id}/edit`
                                    )
                                  }
                                >
                                  <span>{t("edit_element")}</span>
                                </Button>
                              </div>
                            </div>
                          ))}
                      </div>
                    </Row>
                  ))}
                </CardBody>
              </Card>
            )}
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default ServceDetail;
