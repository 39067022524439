import React from "react";
import { useTranslation } from "react-i18next";
import { Table, Button, Row, Col, Input } from "reactstrap";
import { prettify } from "../../../helpers/price-formater";
import { useNavigate } from "react-router-dom";

const AccordionReserve = ({ data, pagination }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const calculateOrderTotalPrice = (order) => {
    let total = 0;
    order?.subOrders?.forEach((subOrder) => {
      subOrder?.items?.forEach((item) => {
        const itemPrice = item?.variation?.prices?.find(
          (price) => price.type === "Price"
        )?.value;
        if (itemPrice) {
          total += itemPrice * item?.count;
        }
      });
    });
    return total;
  };

  return (
  
<div>
<div style={{  overflowX: "auto", maxHeight: `calc(100vh - 250px)`, overflowY: "auto"}}>
    <Table borderless style={{ minWidth: "1500px" }}>
      <thead
        style={{
          position: "sticky",
          top: 0, 
          background: "#fff",
          zIndex: 999,
          
        }}
      >
        <tr >
          <th >{t("orderId")}</th>
          <th>{t("client_full_name")}</th>
          <th>{t("address2")}</th>
          <th></th>
          <th></th>
          <th>{t("SubOrdersID")}</th>
          <th>{t("organization_name")}</th>
          <th></th>
          <th>{t("product_name")}</th>
          <th>{t("Price")}</th>
          <th>{t("quantity")}</th>
          <th>{t("total_price")}</th>
        </tr>
      </thead>
      <tbody>
        {data?.length ? (
          data?.map((order, orderIndex) => {
            const totalPrice = calculateOrderTotalPrice(order);
            const isGrayBackground = orderIndex % 2 === 0;
  
            return (
              <React.Fragment key={`order-${orderIndex}`}>
                <tr
                  className="cursor-pointer"
                  style={{
                    backgroundColor: isGrayBackground ? "#efefef" : "#fff",
                  }}
                  onClick={() => navigate(`/reserve/${order.id}`)}
                >
                  <td>{order.id}</td>
                  <td>{order.fullName}</td>
                  <td>{order.address}</td>
                  <td colSpan={8}></td>
                  <td>{prettify(totalPrice)}</td>
                </tr>
  
                {order?.subOrders?.map((subOrder, subOrderIndex) => (
                  <React.Fragment key={`subOrder-${subOrderIndex}`}>
                    <tr
                      onClick={() => navigate(`/reserve/${order.id}`)}
                      className="cursor-pointer"
                      style={{
                        backgroundColor: isGrayBackground ? "#efefef" : "#fff",
                      }}
                    >
                      <td></td>
                      <td>{order.phoneNumber}</td>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td>{subOrder?.id}</td>
                      <td>{subOrder?.organizationName}</td>
                      <td colSpan={5}></td>
                    </tr>
                    {subOrder?.items?.map((item, itemIndex) => (
                      <tr
                        key={`subOrder-${subOrderIndex}-item-${itemIndex}`}
                        onClick={() => navigate(`/reserve/${order.id}`)}
                        className="cursor-pointer"
                        style={{
                          backgroundColor: isGrayBackground ? "#efefef" : "#fff",
                        }}
                      >
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td>
                          <img
                            className="img-thumbnail rounded avatar-xl"
                            width="200"
                            src={item?.variation?.files?.[0]?.url}
                            alt={item?.variation?.files?.[0]?.id}
                          />
                        </td>
                        <td>{item?.variation?.product?.name}</td>
                        <td>
                          {prettify(
                            item?.variation?.prices?.find(
                              (price) => price.type === "Price"
                            )?.value
                          )}
                        </td>
                        <td>{item?.count}</td>
                        <td></td>
                      </tr>
                    ))}
                  </React.Fragment>
                ))}
              </React.Fragment>
            );
          })
        ) : (
          <tr className="text-center">
            <td colSpan={10}>{t("no_data")}</td>
          </tr>
        )}
      </tbody>
    </Table>
  </div>
    {pagination && (
        <Row className="justify-content-md-end justify-content-center align-items-center p-2">
          <Col className="col-md-auto">
            <div className="d-flex gap-1">
              <Button
                color="primary"
                onClick={() =>
                  pagination?.onChange(pagination.currentPage - 1)
                }
                disabled={pagination?.currentPage <= 1}
              >
                {"<"}
              </Button>
            </div>
          </Col>
          <Col className="col-md-auto d-none d-md-block">
            Page{" "}
            <strong>
              {pagination?.currentPage} of {pagination?.totalPages}
            </strong>
          </Col>
          <Col className="col-md-auto">
            <Input
              type="number"
              min={1}
              style={{ width: 70 }}
              max={pagination?.totalPages}
              defaultValue={pagination?.currentPage}
              readOnly
            />
          </Col>

          <Col className="col-md-auto">
            <div className="d-flex gap-1">
              <Button
                color="primary"
                onClick={() =>
                  pagination?.onChange(pagination.currentPage + 1)
                }
                disabled={pagination?.totalPages === pagination?.currentPage || !data?.length}
              >
                {">"}
              </Button>
            </div>
          </Col>
        </Row>
      )}
</div> 

  );
};

export default AccordionReserve;
