import React, { useEffect, useState } from "react";
import {
  Card,
  CardBody,
  Col,
  Container,
  Row,
  CardHeader,
  Form,
  Button,
  Spinner,
  Label,
} from "reactstrap";
import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import useHookForm from "../../../hooks/useHookForm";
import createSchema from "../../../helpers/createSchema";
import { uploadPhoto, addServiceItem, editServiceItem } from "../../../api";
import { Input } from "../../../Components/atoms/input";
import { useNavigate } from "react-router-dom";
import { useQueryClient, useQuery } from "@tanstack/react-query";
import { getFileIdQuery, getTabQuery } from "../../../queries";
import Dropzone from "react-dropzone";
import { toast, ToastContainer } from "react-toastify";
import { Controller } from "react-hook-form";
import Select from "react-select";
import { config } from "../../../utils/config";
const values = {
  fileId: "",
  order: "",
  link: "",
  languageCode: "",
};

const ServiceItemAdd = () => {
  const [loading, setLoading] = useState(false);
  const [filePreviews, setFilePreviews] = useState("");
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { id, parentId } = useParams();
  const itemId = parseInt(id);
  const parent = parseInt(parentId);
  const QueryClient = useQueryClient();

  const schema = createSchema({
    files: "",
    order: "numberOrder",
    link: "name",
    languageCode: "select"
  });

  const {
    control,
    formState: { errors },
    handleSubmit,
    watch,
    setValue,
    setError,
  } = useHookForm(values, schema);

  const { data: tab } = useQuery({
    ...getTabQuery(parent),
    enabled: parent !== undefined,
  });
  const tabId = tab?.items?.find((item) => item.id === Number(id));
 

  const handleFileUpload = async (acceptedFiles) => {
    const newFiles = acceptedFiles.map((file) => ({
      preview: URL.createObjectURL(file),
    }));
    setFilePreviews(...newFiles.map((f) => f.preview));
    setValue("files", acceptedFiles[0]);
  };

 
  const onSubmit = handleSubmit(async (res) => {
    if (!watch("files")) {
      return setError("files", {
        type: "manual",
        message: t("required"),
      });
    }
  
    const datas = {
      order: res.order,
      link: res.link,
      languageCode: res.languageCode?.value,
    };
  
    try {
      let fileId;
  
      if (watch("files") !== tabId?.fileLink) {
        const formData = new FormData();
        formData.append("files", watch("files"));
  
        const image = await uploadPhoto(formData);
        fileId = image?.[0]?.id;
      } else {
        fileId = tabId?.fileId;
      }
  
      datas.fileId = fileId;
  
      setLoading(true);
  
      if (itemId) {
        await editServiceItem(parent, itemId, datas);
      } else {
        await addServiceItem(parent, datas);
      }
  
      navigate(`/service/${parent}`);
      QueryClient.invalidateQueries(["tab"]);
    } catch (error) {
      alert(error.data?.error);
    } finally {
      setLoading(false);
    }
  });
  
  useEffect(() => {
    if (tabId) {
      setValue("order", tabId?.order);
      setValue("link", tabId?.link);
      setValue(
        "languageCode",

        {
          value: tabId.languageCode,
          label:
            tabId.languageCode === "en"
              ? t("english")
              : tabId.languageCode === "ru-RU"
              ? t("ru-RU")
              : tabId.languageCode === "uz-Latn-UZ"
              ? t("uz-Latn-UZ")
              : tabId.languageCode === "uz-Cyrl-UZ" && t("uz-Cyrl-UZ"),
        }
      );
      const previews = tabId?.fileLink;
      setFilePreviews(previews);
      setValue("files", tabId?.fileLink);
    }
  }, [tab?.items, id]);

  let languages = ["ru-RU", "uz-Cyrl-UZ", "uz-Latn-UZ"];
  if (config.SITENAME === "food") {
    languages.push("en");
    languages = languages.filter((lang) => lang !== "uz-Cyrl-UZ");
  }
  const options = languages.map((lang) => (
  {
    value: lang,
    label: lang === "en"
    ? t("english")
    : lang === "ru-RU"
    ? t("ru-RU")
    : lang === "uz-Latn-UZ"
    ? t("uz-Latn-UZ")
    : lang === "uz-Cyrl-UZ" && t("uz-Cyrl-UZ"),
  }));
  document.title = "Categories Create | Taqsim";
  return (
    <div className="page-content">
      <Container fluid>
        <Row>
          <Col xl={8}>
            <Card>
              <CardHeader>
                <div className="d-flex align-items-center gap-3">
                  <i
                    className="ri-arrow-left-line fs-4 cursor-pointer"
                    onClick={() => navigate(-1)}
                  ></i>
                  <h5
                    className="card-title flex-grow-1 mb-0"
                    style={{ fontSize: "20px", fontWeight: 700 }}
                  >
                    {id ? t("edit_element") : t("add_element")}
                  </h5>
                </div>
              </CardHeader>
              <CardBody>
                <Form action="#">
                  <div className="d-flex gap-4 flex-wrap flex-column">
                    <Input
                      control={control}
                      errors={errors}
                      name="order"
                      label="order"
                      isInteger
                    />
                    <Input
                      control={control}
                      errors={errors}
                      name="link"
                      label="link"
                    />
                    <div>
                      <Label>{t("choose_language")}</Label>
                      <Controller
                        name="languageCode"
                        control={control}
                        
                        render={({ field }) => (
                          <Select
                            {...field}
                            options={options}
                          />
                        )}
                      />
                       {errors.languageCode && (
                        <span className="text-danger">
                          {t(errors.languageCode.message)}
                        </span>
                      )}
                    </div>
                    <div className="w-100">
                      <Label>{t("image")}</Label>
                      <div className="d-flex gap-2 flex-wrap w-100">
                        <div style={{ marginBottom: "20px", width: "100%" }}>
                          <Dropzone
                            accept="image/jpeg, image/png, image/jpg,"
                            onDrop={(acceptedFiles) =>
                              handleFileUpload(acceptedFiles)
                            }
                          >
                            {({ getRootProps, getInputProps }) => (
                              <div
                                {...getRootProps({ className: "dropzone" })}
                                style={{
                                  display: "flex",
                                  alignItems: "center",
                                  justifyContent: "center",
                                  flexDirection: "column",
                                  height: "80px",
                                  border: "2px dashed #cccccc",
                                  borderRadius: "5px",
                                  cursor: "pointer",
                                  width: "100%", // Make the Dropzone take full width
                                }}
                              >
                                {!filePreviews ? (
                                  <div className="d-flex align-items-center justify-content-center">
                                    <i className="bx bx-image-add display-4"></i>
                                  </div>
                                ) : (
                                  <img
                                    src={filePreviews}
                                    alt="Img"
                                    style={{
                                      maxWidth: "100%",
                                      maxHeight: "100%",
                                      objectFit: "cover",
                                      borderRadius: "5px",
                                    }}
                                  />
                                )}
                              </div>
                            )}
                          </Dropzone>
                        </div>
                      </div>
                      {errors.files && (
                        <span className="text-danger">
                          {errors.files.message}
                        </span>
                      )}
                      <div >
                        {
                          tab?.order === 0 ? (
                            <div className="d-flex flex-column gap-1">
                            <span>
                            {  t("recommended_size_858_397")} 
                          </span>
                    <span>     {     t("recommended_size_297_297")}</span>
                            </div>
                          ) : t("recommended_size_1200_350")
                        }
                      </div>
                    </div>
                  </div>

                  <Button
                    color="success"
                    className="btn btn-success w-100 mt-3"
                    type="submit"
                    onClick={onSubmit}
                  >
                    {loading ? (
                      <Spinner size="sm" className="me-2">
                        {" "}
                        Loading...{" "}
                      </Spinner>
                    ) : null}
                    {t("save")}
                  </Button>
                </Form>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default ServiceItemAdd;
