import React, { useEffect, useState } from "react";
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  Form,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Spinner,
} from "reactstrap";
import useHookForm from "../../../hooks/useHookForm";
import createSchema from "../../../helpers/createSchema";
import { Input } from "../../../Components/atoms/input";
import Dropzone from "react-dropzone";
import RadioButtonInput from "../ui/radio-button";
import { createBrand, deleteBrand, uploadPhoto } from "../../../api";
import { useNavigate, useParams } from "react-router-dom";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { getBrandById } from "../../../queries";
import { useTranslation } from "react-i18next";
import { toast, ToastContainer } from "react-toastify";
const values = {
  name: "",
  isVisible: null,
  imageId: "",
  iconId: "",
};

const schema = createSchema({
  name: "required",
  files: "",
  files2: "",
});
const BrandSingle = () => {
  const { id } = useParams();
  const { data } = useQuery({
    ...getBrandById(id),
  });
  const { mutate, isError } = useMutation({
    mutationFn: (id) => deleteBrand(id),
  });

  const [filePreviews, setFilePreviews] = useState("");
  const [filePreviews2, setFilePreviews2] = useState("");
  const [isVisible, setIsVisible] = useState(false);
  const [loading, setLoading] = useState(false);
  const queryClient = useQueryClient();
  const [modal, setModal] = useState(false);

  const { t } = useTranslation();
  const navigate = useNavigate();
  const {
    setValue,
    control,
    formState: { errors },
    handleSubmit,
    watch,
    setError,
  } = useHookForm(values, schema);
  function openModal() {
    setModal(!modal);
  }
  const handleDelete = (id) => {
    setLoading(true);
    mutate(id, {
      onSuccess: () => {
        toast.success(t("Successfully"));
        queryClient.invalidateQueries({ queryKey: ["brand"] });
        navigate("/brands");
      },
      onError: (error) => {
        console.log(error);
        
        if (error.status === 500) {
          toast.error(t("brand_with_the_product")); 
        } else {
          alert(error.response?.data?.error?.errorMessage); 
        }
      },
      onSettled: () => {
        setLoading(false);
      },
    });
  };

  useEffect(() => {
    if (data) {
      setValue("name", data?.name);
      const preview = data?.image?.url;
      setValue("files", preview);
      setFilePreviews(preview);
      const previewIcon = data?.icon?.url;
      setFilePreviews2(previewIcon);
      setValue("files2", previewIcon);
      setIsVisible(data?.isVisible);
    }
  }, [data]);

  
  return (
    <div className="page-content">

      <ToastContainer position="top-center" theme="colored" />
      <Card>
        <CardHeader>
          <div className="d-flex align-items-center gap-3">
            <i
              className="ri-arrow-left-line fs-3 cursor-pointer"
              onClick={() => navigate(-1)}
            ></i>
            <h5
              className="card-title flex-grow-1 mb-0"
              style={{ fontSize: "20px", fontWeight: 700 }}
            >
              {t("brand") + " " + "№" + " " + data?.id}
            </h5>

            <Button onClick={() => openModal()} color="danger">
              {t("delete")}
            </Button>
          </div>
        </CardHeader>
        <CardBody>
          <Input
            name="name"
            control={control}
            errors={errors}
            label="brand_name"
            inputProps={{
              placeholder: t("enter_name"),
              readOnly: true,
            }}
          />

          <div className="d-flex align-items-center gap-5">
            <div className="d-flex gap-2 flex-wrap mt-3">
              <div>
                <Label>{t("image")}</Label>
                <div style={{ width: "300px" }}>
                  <Dropzone disabled>
                    {({ getRootProps, getInputProps }) => (
                      <div
                        {...getRootProps({ className: "dropzone" })}
                        style={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                          flexDirection: "column",
                          height: "80px",
                          border: "2px dashed #cccccc",
                          borderRadius: "5px",
                          cursor: "pointer",
                        }}
                      >
                        {!filePreviews && (
                          <div className="d-flex align-items-center justify-content-center">
                            <i className="bx bx-image-add display-4"></i>
                          </div>
                        )}
                        {filePreviews && (
                          <img
                            src={filePreviews}
                            alt={`Img`}
                            style={{
                              maxWidth: "100%",
                              maxHeight: "100%",
                              objectFit: "cover",
                              borderRadius: "5px",
                            }}
                          />
                        )}
                      </div>
                    )}
                  </Dropzone>
                </div>
                {errors.files && (
                  <span className="text-danger">{errors.files.message}</span>
                )}
              </div>
            </div>
            <div className="d-flex gap-2 flex-wrap mt-3">
              <div style={{ width: "300px" }}>
                <Label>{t("icon")}</Label>
                <Dropzone disabled>
                  {({ getRootProps, getInputProps }) => (
                    <div
                      {...getRootProps({ className: "dropzone" })}
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        flexDirection: "column",
                        height: "80px",
                        border: "2px dashed #cccccc",
                        borderRadius: "5px",
                        cursor: "pointer",
                      }}
                    >
                      {!filePreviews2 && (
                        <div className="d-flex align-items-center justify-content-center">
                          <i className="bx bx-image-add display-4"></i>
                        </div>
                      )}
                      {filePreviews2 && (
                        <img
                          src={filePreviews2}
                          alt={`Img`}
                          style={{
                            maxWidth: "100%",
                            maxHeight: "100%",
                            objectFit: "cover",
                            borderRadius: "5px",
                          }}
                        />
                      )}
                    </div>
                  )}
                </Dropzone>
                {errors.files2 && (
                  <span className="text-danger">{errors.files2.message}</span>
                )}
              </div>
            </div>
          </div>
          <div className="mt-2">
            <RadioButtonInput
              disabled
              label={"isVisible"}
              state={isVisible}
              setState={setIsVisible}
              id={1}
            />
          </div>
        </CardBody>
      </Card>

      <Modal
        isOpen={modal}
        toggle={() => {
          openModal();
        }}
        modalClassName="zoomIn"
        centered
        size="sm"
      >
        <ModalHeader>{t("delete_confirmation")}</ModalHeader>
        <ModalBody>
          <i
            className=" ri-alert-fill text-warning d-flex justify-content-center"
            style={{ fontSize: "50px" }}
          ></i>
          <p className="text-center">{t("text_delete")}</p>
        </ModalBody>
        <ModalFooter>
          <Button onClick={() => setModal(false)}>{t("cancel")}</Button>
          <Button onClick={() => handleDelete(id)} className="btn btn-danger">
            {loading ? <Spinner size="sm" className="me-2" /> : null}{" "}
            {t("delete")}
          </Button>
        </ModalFooter>
      </Modal>
    </div>
  );
};

export default BrandSingle;
